.text-right {
  text-align: right !important;
}

.border-top {
  border-top: 1px solid $color__primary !important;
}

.border-bottom {
  border-bottom: 1px solid $color__primary !important;
}

@include media-breakpoint-down(md) {
  .no-mobile-border {
    border: none !important;
  }
}

.desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
