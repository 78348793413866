.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-radius: 0 !important;
  background: var(--secondaryColor) !important;
  box-shadow: none !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: var(--primaryColor) !important;
  font-family: $font__main !important;
}
