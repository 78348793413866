@use '@angular/material' as mat;

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.dialog-overrides((
    actions-padding: 24px,
  ));
}

@include mat.dialog-theme((
  container: (
    min-width: 100%, // Standard
    desktop: 600px   // Desktop (min-width gilt nur ab größerer Viewport)
  )
));

.mat-mdc-dialog-container {
  border-radius: 24px;
}


html {

  /* shadows */
  --mdc-dialog-container-elevation-shadow: none;
  --mdc-dialog-container-shadow-color: none;

  /* border-radius */
  --mdc-dialog-container-shape: 24px;

  /* color */
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: var(--color-primary);
  --mdc-dialog-supporting-text-color: var(--color-primary);

  /* typography */
  --mdc-dialog-subhead-font: var(--font-main);
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: normal;
  --mdc-dialog-subhead-tracking: .0125em;
  --mdc-dialog-supporting-text-font: var(--font-main);
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: normal;
  --mdc-dialog-supporting-text-tracking: .03125em
}
