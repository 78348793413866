.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: var(--surfaceColor) !important;
  --mdc-chip-elevated-disabled-container-color: var(--surfaceColor) !important;
  --mdc-chip-label-text-color: var(--primaryColor) !important;
  --mdc-chip-disabled-label-text-color:  var(--primaryColor) !important;
  --mdc-chip-with-icon-icon-color:  var(--primaryColor) !important;
  --mdc-chip-with-icon-disabled-icon-color:  var(--primaryColor) !important;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color:  var(--primaryColor) !important;
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--primaryColor) !important;
  --mdc-chip-with-icon-selected-icon-color: var(--primaryColor) !important;
}

.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: var(--secondaryColor) !important;
  --mdc-chip-elevated-disabled-container-color: var(--secondaryColor) !important;
  --mdc-chip-label-text-color:  var(--primaryColor) !important;
  --mdc-chip-disabled-label-text-color:  var(--primaryColor) !important;
  --mdc-chip-with-icon-icon-color:  var(--primaryColor) !important;
  --mdc-chip-with-icon-disabled-icon-color:  var(--primaryColor) !important;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color:  var(--primaryColor) !important;
  --mdc-chip-with-trailing-icon-trailing-icon-color:  var(--primaryColor) !important;
  --mdc-chip-with-icon-selected-icon-color:  var(--primaryColor) !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--selectable:not(.mdc-evolution-chip--with-primary-icon) {
  height: 38px;
  border-radius: 100px;
  padding: 0 12px;
}
