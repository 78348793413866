.stepper-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--primaryColor);
  z-index: 10;

  .row {
    height: 72px;

    @include media-breakpoint-down(md) {
      height: 62px;
    }
  }

  .stepper-previous {
    color: white;
  }

  .stepper-next {
    color: var(--primaryColor);
    background: white;
    transition: opacity 0.5s;
  }

  .stepper-next[disabled] {
    color: var(--primaryColor);
    background: white;
    opacity: 0.5;
  }

  @include media-breakpoint-down(md) {
    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 44px;
      height: 44px;
      padding: 8px;
    }
  }

}
