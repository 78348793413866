/* You can add global styles to this file, and also import other style files */
/*--------------------------------------------------------------
# Reset
--------------------------------------------------------------*/
@import "assets/css/generic/reset";

/*--------------------------------------------------------------
# Vendors
--------------------------------------------------------------*/
//@import "assets/css/vendors/bootstrap5/bootstrap";


// Core variables and mixins
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Required dependencies for subsequent components
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/utilities";

// Core components
//@import "../../../node_modules/bootstrap/scss/root";
//@import "../../../node_modules/bootstrap/scss/reboot";
//@import "../../../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/utilities/api";
@import "../node_modules/bootstrap/scss/helpers";

/*--------------------------------------------------------------
# Abstracts
--------------------------------------------------------------*/
@import "assets/css/abstracts/abstracts";

/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/
@import "assets/css/generic/utilities";
@import "assets/css/generic/box-sizing";
@import "assets/css/generic/spacer";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "assets/css/base/base";
@import "assets/css/components/layout/theme";

/*--------------------------------------------------------------
# Angular Material
--------------------------------------------------------------*/
@import "assets/css/material/typography";
@import "assets/css/material/buttons";
@import "assets/css/material/checkbox";
@import "assets/css/material/radio";
@import "assets/css/material/input";
@import "assets/css/material/stepper";
@import "assets/css/material/selection-list";
@import "assets/css/material/select-field";
@import "assets/css/material/chips";
@import "assets/css/material/spinner";
@import "assets/css/material/expansion-panel";
@import "assets/css/material/paginator";
@import "assets/css/material/menu";
@import "assets/css/material/snackbar";
@import "assets/css/material/dialog";

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import "assets/css/components/components";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.full-width {
  width: 100%;
}
