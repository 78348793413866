.mat-mdc-selection-list {
  //border-bottom: 1px solid var(--primaryColor);
}

.mat-mdc-list-item {
  .mdc-list-item__primary-text {
    font-family: $font__main !important;
    color: var(--primaryColor) !important;
  }

  &:hover,
  &:focus {
    background: var(--surfaceColor) !important;
  }
}


.mat-mdc-list-option.mat-accent .mdc-list-item__end {
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #65352A;
    --mdc-checkbox-selected-hover-icon-color: #65352A;
    --mdc-checkbox-selected-icon-color: #65352A;
    --mdc-checkbox-selected-pressed-icon-color: #65352A;
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-unselected-icon-color: #65352A;
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
