#header {
  height: 72px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--surfaceColor);

  @include media-breakpoint-down(md) {
    height: 56px;
  }

  .container {
    position: relative;
  }

  .logo {
    height: 56px;
    @include media-breakpoint-down(md) {
      height: 40px;
    }
  }

  .mdc-icon-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
