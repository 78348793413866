.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: var(--primaryColor) !important;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: var(--secondaryColor) !important;
}

.mdc-linear-progress {
  position: absolute !important;
  height: 100% !important;
  opacity: 0.4;
}
