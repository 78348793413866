.mat-mdc-paginator {
  color: var(--primaryColor) !important;
  font-family: $font__main !important;
}

.mat-mdc-paginator-icon:not([disabled]){
    fill: var(--primaryColor) !important;
}

.mat-mdc-paginator .mat-mdc-icon-button[disabled] {
    opacity: 0.3 !important;
}

.mat-mdc-paginator .mat-mdc-icon-button:hover {
  background: var(--surfaceColor);
}
