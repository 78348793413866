/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 6, 2022 */
@font-face {
  font-family: 'title';
  src: url('/assets/fonts/GT-America-Standard-Regular.woff2') format('woff2'),
  url('/assets/fonts/GT-America-Standard-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'txt';
  src: url('/assets/fonts/GT-America-Standard-Regular.woff2') format('woff2'),
  url('/assets/fonts/GT-America-Standard-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-main: 'txt', sans-serif;
}


$font__main: 'txt', sans-serif;
$font__title: 'title', serif;
