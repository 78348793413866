.swiper-img {
  overflow: hidden;
  aspect-ratio: 3 / 4;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}


