.canvas-wrapper {
  overflow: hidden;
  border: 1px dashed var(--primaryColor);
  border-radius: 5px;
}


.cdk-overlay-pane {
  max-width: calc(100vw - 12px) !important;
}


.mat-mdc-dialog-container {
  overflow: hidden;

  .row {
    width: auto;
  }

  .container-dialog {
    padding: 24px;
  }

  a {
    &:hover {
      cursor: pointer;
    }
  }

  .mdc-dialog__title {
    color: var(--primaryColor) !important;
    margin: 0;
    padding: 0;
    padding-bottom: 24px;
  }
}


.pdf-wrapper {
  @include media-breakpoint-down(md) {
    margin-right: 12px;
  }

}

.pdf-content {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 48px;
  margin-top: 48px;
  color: black;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 300;

  -webkit-box-shadow: 0px 0px 14px 0px rgba(132, 125, 105, 0.4);
  box-shadow: 0px 0px 14px 0px rgba(132, 125, 105, 0.4);


  @include media-breakpoint-down(md) {
    padding: 12px;
    margin-top: 12px;
    font-size: 8px;
    line-height: 1.4;

    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid var(--surfaceColor);

    -webkit-box-shadow: 0px 0px 12px 0px rgba(132, 125, 105, 0.1);
    box-shadow: 0px 0px 12px 0px rgba(132, 125, 105, 0.1);
  }

  .row {
    margin-bottom: 24px;
    @include media-breakpoint-down(md) {
      margin-bottom: 12px;
    }
  }

  .col,
  .col-3 {
    position: relative;
    padding-left: 0;
  }

  .canvas-signature {
    position: absolute;
    top: 0;
    left: 53px;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 2.8cm;
    transform: translateY(-80%);

    @include media-breakpoint-down(md) {
      left: 0px;
    }
  }

  .row-signature {
    padding-top: 60px;

    @include media-breakpoint-down(md) {
      padding-top: 24px;
    }

    .col {
      border-top: 1px solid black;
      padding-top: 6px;
      padding-left: 0;
      margin-right: 48px;
    }
  }

  .bsc-logo {
    position: absolute;
    width: 120px;
    height: auto;
    right: 60px;
    top: 70px;

    @include media-breakpoint-down(md) {
      position: absolute;
      width: 55px;
      height: auto;
      right: 24px;
      top: 24px;
    }
  }

  h2 {
    border-top: 1px solid #0075bf;
    padding-top: 12pt;
    padding-bottom: 24pt;
    font-size: 12pt;
    line-height: 1.55;
    font-weight: bold;
    color: #0075bf;

    @include media-breakpoint-down(md) {
      padding-top: 12px;
      padding-bottom: 24px;
      font-size: 10px;
      line-height: 1.4;
      width: calc(100% - 67px);
    }
  }

  p {
    padding-bottom: 12px;
  }

  ul {
    list-style-type: square;
    margin-left: 12px;
    margin-bottom: 12px;

    li::marker {
      color: #0075bf;
    }
  }
}
