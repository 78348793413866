.mat-button-base.mat-primary {
  background-color: var(--primaryColor) !important;
  border-radius: 0 !important;
}

.mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: #65352A !important;
}

.mat-mdc-button, .mat-mdc-unelevated-button, .mat-mdc-raised-button, .mat-mdc-outlined-button {
    -webkit-tap-highlight-color: #F0EFEC !important;
}

.mat-mdc-button {
  border-radius: 0 !important;
  font-size: 16px;
  letter-spacing: 0;
  &:hover {
    background: var(--surfaceColor);
  }
}

.button-secondary-color {
  background: var(--secondaryColor) !important;
  color: var(--primaryColor) !important;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
    height: 56px;
    padding: 0 16px;
}

/*---------------------------------------------------------------------
Flat Button
 ---------------------------------------------------------------------*/

.mat-mdc-unelevated-button.mat-primary {
    background: var(--primaryColor) !important;
    --mdc-filled-button-label-text-color: #fff;
}

.white-button {
  background: white !important;
  color: var(--primaryColor) !important;
}

/*---------------------------------------------------------------------
Stroke Button
 ---------------------------------------------------------------------*/

.mat-mdc-outlined-button.mat-primary {
    --mdc-outlined-button-label-text-color: var(--primaryColor) !important;
}

.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
    --mat-mdc-button-persistent-ripple-color: var(--primaryColor);
}

.mat-mdc-outlined-button:not(:disabled) {
    border-color: var(--primaryColor) !important;
}

/*---------------------------------------------------------------------
FAB Button
 ---------------------------------------------------------------------*/
.mat-mdc-extended-fab {
  color: white !important;
  background: var(--primaryColor) !important;
  box-shadow: none !important;

   @include media-breakpoint-down(md) {
    width: calc(100% - 12px) !important;
  }
}


/*---------------------------------------------------------------------
Button disabled
 ---------------------------------------------------------------------*/
.mat-mdc-button[disabled][disabled] {
  background: var(--surfaceColor) !important;
    //--mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
    //--mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

/*---------------------------------------------------------------------
Button rounded
 ---------------------------------------------------------------------*/
.button-rounded {
  border-radius: 100px !important;
}

.button-small {
  height: 42px !important;
  padding: 0 16px !important;
}
