/*:root {
  --primaryColor: #65352A;
  --secondaryColor: #E2CBC0;
  --surfaceColor: #F0EFEC;
}

 */


body, html {
  overflow: hidden !important;
  .scroll-body {
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
