/*
.mdc-text-field__input,
.mat-mdc-form-field-required-marker,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  font-family: $font__main !important;
  color: var(--primaryColor);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $color__alert;
}

.mdc-text-field {
  border-radius: 0 !important;
  background: var(--surfaceColor) !important;
}

.mdc-line-ripple::before {
    border-bottom-width: 0px !important;
}

.mat-mdc-form-field-infix {
  width: auto !important;
}

 */

html {
  /* size */
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 0px;

  /* primary/default color */
  --mdc-filled-text-field-caret-color: var(--color-primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--color-primary);
  --mdc-filled-text-field-focus-label-text-color: var(--color-primary);
  --mdc-filled-text-field-container-color: var(--color-surface);
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-filled-text-field-label-text-color: var(--color-primary);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, .38);
  --mdc-filled-text-field-input-text-color: var(--color-primary);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, .38);
  --mdc-filled-text-field-input-text-placeholder-color: var(--color-primary);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: var(--color-primary);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, .06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, .87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;

  /* typography */
  --mdc-filled-text-field-label-text-font: var(--font-main);
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: .03125em;
  --mdc-filled-text-field-label-text-weight: 400;
}

.mat-mdc-form-field.mat-accent {
  /* accent color */
  --mdc-filled-text-field-caret-color: #69f0ae;
  --mdc-filled-text-field-focus-active-indicator-color: #69f0ae;
  --mdc-filled-text-field-focus-label-text-color: rgba(105, 240, 174, .87);
}

.mat-mdc-form-field.mat-warn {
  /* warn color */
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, .87);
}
