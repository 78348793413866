/*.theme {
  color: var(--primaryColor);

  --mdc-theme-primary: var(--primaryColor) !important;
  --mdc-theme-secondary: var(--secondaryColor) !important;
  --mdc-theme-surface: var(--surfaceColor) !important;
  --mdc-theme-text-primary-on-background: var(--primaryColor) !important;
}

 */
