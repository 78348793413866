.mat-radio-outer-circle {
.mat-radio-checked & {
      border-color: var(--primaryColor) !important;
    }
}

.mat-radio-inner-circle {
    background-color: var(--primaryColor) !important;
}

.mat-radio-ripple .mat-ripple-element {
    background-color: rgba(var(--primaryColor), 0.26) !important;
}
