[appViewportObserver] {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

[appFadeIn] {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}
