.card {
  position: relative;
  padding: 12px 12px 24px 12px;
  background: var(--surfaceColor);
  margin-bottom: 24px;

  button {
    position: absolute;
    right: 12px;

    @include media-breakpoint-down(md) {
      right: 0px;
      top: 0;
    }
  }
}

.card-square {
  aspect-ratio: 4/3;
  padding: 24px;
  @include media-breakpoint-down(lg) {
    aspect-ratio: 1/1;
  }
  @include media-breakpoint-down(md) {
    display: grid;
    flex-direction: column; /* to make the card-icon and card-content stack vertically*/
    aspect-ratio: 4/3;
  }
}

.card-icon {
  align-self: flex-start; /* aligns the card-icon to the top */
  @include media-breakpoint-up(md) {
    padding-top: 24px;
  }
}

.card-content {
  align-self: flex-end; /* aligns the card-content to the bottom */
}
