html,
body,
button,
input,
select,
optgroup,
textarea {
  font-family: $font__main !important;
  font-size: 16px;
  line-height: 24px;
  color: var(--primaryColor);
  -webkit-font-smoothing: antialiased;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  font-feature-settings: "kern" 1, "pnum" 1;

  @include media-breakpoint-down(md) {
    font-size: 14px;
    line-height: 1.5;
  }
}

.hyphanate {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@import "headings";
//@import "copy";
