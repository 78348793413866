.container {
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  //max-width: 100vw;
}

.container-mb {
  padding-bottom: 200px;
  @include media-breakpoint-down(md) {
    //padding-bottom: 100px;
  }
}

.col-contract {
  margin-top: 17px;

  @include media-breakpoint-down(md) {
    margin-top: 0px;
    margin-bottom: 12px;
  }
}
