/*--------------------------------------------------------------
  Spacer Styles

  1. Spacer Padding Top
  2. Spacer Padding Bottom
  3. Conditional Spacer
--------------------------------------------------------------*/

.pt-xl {
  padding-top: 96px;
}

.pt-lg {
  padding-top: 72px;
}

.pt-md {
  padding-top: 48px;
}

.pt-sm {
  padding-top: 24px;
}

.pt-xsm {
  padding-top: 12px;
}

@include media-breakpoint-down(md) {
  .pt-xl,
  .pt-lg,
  .pt-md {
    padding-top: 24px;
  }

  .pt-sm {
    padding-top: 12px;
  }
}

/*--------------------------------------------------------------
# Paddinb Bottom
--------------------------------------------------------------*/
.pb-xl {
  padding-bottom: 96px;
}

.pb-lg {
  padding-bottom: 72px;
}

.pb-md {
  padding-bottom: 48px;
}

.pb-sm {
  padding-bottom: 24px;
}

.pb-xsm {
  padding-bottom: 12px;
}

@include media-breakpoint-down(md) {
  .pb-xl,
  .pb-lg,
  .pb-md {
    padding-bottom: 24px;
  }

  .pt-sm {
    padding-bottom: 12px;
  }
}


/*--------------------------------------------------------------
# Conditional Spacer
--------------------------------------------------------------*/
@include media-breakpoint-down(md) {
  .no-mobile-padding {
    padding: 0 !important;
  }
}
