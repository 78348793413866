h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font__title !important;
}

h1 {
  font-size: 28px;
  line-height: 1;
}

h2 {
  font-size: 20px;
  line-height: 1.5;
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: 20px;
    line-height: 1;
  }

  h2 {
    font-size: 14px;
    line-height: 1.5;
  }
}

.overline {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.headline-1 {
  font-size: 34px;
  line-height: 1.25;

  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
}

.headline-2 {
  font-size: 22px;
  line-height: 1.3;

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }
}


.headline-3 {
  font-size: 20px;
  line-height: 1.5;

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}
