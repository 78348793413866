$color__primary: #65352A;
$color__secondary: #E2CBC0;
$color__accent: #E2CBC0;
$color__surface: #F0EFEC;

$color__alert: red;
$color__sucess: green;

:root {
  --color-primary: #65352A;
  --color-secondary: #E2CBC0;
  --color-accent: #E2CBC0;
  --color-surface: #F0EFEC;
  --color-alert: red;
  --color-sucess: green;
}
