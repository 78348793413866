/*--------------------------------------------------------------
  Angular Material Stepper
  https://material.angular.io/components/stepper/overview

  1. Step Header
  2. Horizontal Stepper
  3. Vertical Stepper

--------------------------------------------------------------*/

.mat-step-header {
  .mat-step-icon {
    color: var(--primaryColor) !important;
    font-family: $font__main !important;
    background: var(--surfaceColor) !important;
  }

  .mat-step-icon-selected {
    background: var(--secondaryColor) !important;
  }

  .mat-step-icon-state-edit {
    color: white !important;
    background: var(--primaryColor) !important;
  }

  .mat-step-label {
    display: block !important;
    font-family: $font__main !important;
    color: var(--primaryColor) !important;
    font-size: 16px !important;
  }

  &:hover {
    .mat-step-icon {
      color: white !important;
      background: var(--primaryColor) !important;
    }
  }
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused {
  background: white !important;
}

.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled=false] {
  background-color: var(--surfaceColor) !important;
}


/*--------------------------------------------------------------
# Horizontal Stepper
--------------------------------------------------------------*/
.mat-horizontal-stepper-header {
  display: block !important;
  padding-top: 24px !important;
  height: 96px !important;
  text-align: center !important;
}

.mat-horizontal-stepper-header .mat-step-icon {
  display: inline-block;
  width: 42px;
  height: 42px;
}


/*--------------------------------------------------------------
# Vertical Stepper
--------------------------------------------------------------*/
.mat-vertical-content {
  padding: 0 !important;
}

.mat-vertical-stepper-header {
  //background: var(--surfaceColor);
  margin-bottom: 12px;
}

.mat-vertical-stepper-header {
  padding-left: 12px !important;
}

.mat-vertical-content-container {
  margin-left: 24px !important;
  margin-right: 12px !important;
}

.mat-vertical-stepper-content {
  margin-left: 24px;
}

.mat-stepper-vertical-line::before {
  //left: 0px !important;
  border-color: var(--primaryColor);
}

/*--------------------------------------------------------------
# Vertical Stepper Last Element
--------------------------------------------------------------*/

.mat-step {
  &:last-of-type {
    .mat-vertical-content-container {
      margin-left: 0 !important;
      width: 100%;
    }

    .mat-vertical-stepper-content {
      margin-left: 12px;
    }
  }
}

