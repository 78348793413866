.expansion-panel-headers-align .mat-expansion-panel-header-description {
  justify-content: end;
  align-items: center;

  span {
    padding-right: 12px;
  };
}

.expansion-panel-headers-align .mat-mdc-form-field + .mat-mdc-form-field {
  margin-left: 8px;
}

.mat-expansion-panel {
  font-family: $font__main !important;
  box-shadow: none !important;
  border-bottom: 1px solid var(--primaryColor);
  border-radius: none !important;
}

.mat-expansion-panel,
.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after,
.mat-expansion-panel-content {
  font-family: $font__main !important;
  color: var(--primaryColor) !important;
}

.mat-expansion-panel-header-description {
  padding-left: 7px;
}

.mat-accordion .mat-expansion-panel:first-of-type,
.mat-accordion .mat-expansion-panel:last-of-type,
.mat-expansion-panel {
  border-radius: 0 !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.mat-expansion-panel-body,
.mat-expansion-panel .mat-expansion-panel-header[aria-expanded=true]{
  //background: var(--surfaceColor) !important;
}

.mat-expansion-panel-header[aria-expanded=true]{
  //border-top: 1px solid var(--primaryColor);
}

.mat-divider {
  border-top-color: var(--primaryColor);
}


