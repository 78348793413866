.mat-mdc-option {
  .mdc-list-item__primary-text {
    font-family: $font__main !important;
    color: var(--primaryColor) !important;
  }
  &:hover {
    background: var(--surfaceColor) !important;
  }
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: var(--surfaceColor) !important;
}

.mat-mdc-form-field-type-mat-select .mdc-line-ripple--active {
  display: none;
}

.mat-mdc-select-arrow {
  display: none !important;
}

