/* --------------------------------------------------

   Breakpoint Settings
   -------------------------------------------------- */

// min or max
$media-direction:       max;  // min / max — default is min (mobile first)

// Breakpoints
// A Sass Map for all Breakpoints you need. But keep it simple! Just add Sizes that you need several times.
// Here are my basic set. I change the values a bit from project to project
$breakpoints: (
  'default':            '',
  'xs':                 480px,
  's':                  667px,
  'm':                  1024px,
  'l':                  1280px,
  'xl':                 1680px,
);


/* --------------------------------------------------

   Breakpoint Mixin
   -------------------------------------------------- */

// $breakpoint = die Größe die ihr braucht.
// $direction: $media-direction = das was ihr in den Settings eingestellt habt.
@mixin breakpoint($breakpoint,$direction: $media-direction) {

  // Es wird gecheckt ob der Wert in der Sass Map vorhanden ist
  @if map-has-key($breakpoints, $breakpoint) {

    // Ausgabe
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  // Wenn ihr oben einen Wert eingetragen habt wird hier gecheckt ob ihr einen Einheit angehängt habt. Falls nicht wird px genutzt.
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    // Ausgabe
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}
